.error-label {
  font-size: 12px;
  margin: 8px 0 0 16px;
  color: var(--warning-error);
}

.key-ctrl:disabled {
  cursor: not-allowed;
}

.products {
  .preview {
    display: flex;
    margin: 20px 0 0 0;

    p {
      min-width: 400px;
      color: var(--text-gray);
      font-style: italic;
      margin: 0;
    }
  }
}