.container.cache-reloader {
  padding: 0;
}

.container.cache-reloader .card {
  margin: 32px 24px;
}

.container.cache-reloader .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.cache-reloader .card-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;
}

.container.cache-reloader .row {
  margin-bottom: 24px;
}

.container.cache-reloader .card-text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 700;
}
