.container.dashboard {
  padding: 0;
}

.container.dashboard .card {
  margin: 32px 24px;
}

.container.dashboard .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.dashboard .card-title {
  font-size: 32px;
  font-weight: 700;
}

.container.dashboard .card-text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 700;
}

.container.dashboard .row.title ~ .card-text {
  margin-bottom: 24px;
}

.container.dashboard .row.title ~ .card-text.input-description {
  margin-bottom: 15px;
}

.container.dashboard .row {
  margin-bottom: 24px;
}

.container.dashboard .row.title {
  margin-bottom: 8px;
}

.container.dashboard .row.dates .col-md-2 {
  min-width: 200px;
}

.container.dashboard .row .col-md-3.submit {
  display: flex;
}

.container.dashboard .row .col-md-3.submit > .btn {
  align-self: flex-end;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 38px;
}
