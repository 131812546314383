.step-move-cell {
  display: flex;
  opacity: 0;
  transition: all 100ms ease;
}

.MuiTableRow-root:hover {
  .step-move-cell {
    opacity: 1;
  }
}
