.container.scenario-editor-container {
  padding: 0;
}

.container.scenario-editor-container .card {
  margin: 32px 24px;
}

.manual-model-table {
  border: none;
}

.actions-footer {
  display: flex;
  justify-content: space-between;

  .our-btn.quaternary.back-btn {
    margin: auto 0 auto 32px !important;
  }

  .reset-changes-btn {
    color: var(--warning-error);
    border-color: var(--warning-error);

    margin: auto 48px auto 0 !important;
  }
}