.guest-aside-main {
  display: flex;
}

.guest-aside-main aside {
  position: sticky;
  top: 56px;
  height: calc(100vh - 56px);
}

.guest-aside-main main {
  width: 100%;
}
