.popup-menu-container {
  // width: 400px;

  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid var(--detail-border);

    header {
      display: flex;
      align-items: center;
      white-space: nowrap;
      gap: 32px;
      padding: 0 12px 8px 12px;
      cursor: default;

      h6 {
        font-weight: bold;
        margin-bottom: 0;
      }

      border-bottom: 1px solid var(--detail-border);

      button {
        padding: 0;
      }
    }
  }
}
