.container.scenario-details-container {
  padding: 0;
}

.container.scenario-details-container .card {
  margin: 32px 24px;
}

.container.scenario-details-container .card-title {
  font-size: 32px;
  font-weight: 700;
}

.container.scenario-details-container .row {
  margin-bottom: 24px;
}

.scenario-details-container .scenario-details .title .card-title:not(:first-child) {
  margin-left: 1rem;
  color: #715fcc;
}

.scenario-steps-container {
  display: flex;
  flex-direction: column;
}

.steps-table-container {
  table {
    padding-bottom: 12px;
  }
}
