.save-inline-notification-description {
  display: flex;
  align-items: center;

  .spinner {
    border-color: var(--text-gray);
    border-right-color: transparent;
  }
}

.scenario-steps-container {
  position: relative;

  .add-parent-step-btn {
    position: absolute;
    top: 0;
    right: 16px;
    transform: translateY(50%);
    z-index: 999;

    font-size: 16px;
    color: var(--bg-white);
    border-bottom: 1px solid var(--bg-white) !important;
  }

  .step-adder {
    position: relative;
    width: 100%;
    height: 1px;
    cursor: pointer;
    z-index: 998;

    td {
      padding: 0;
    }

    background-color: transparent;

    &::after {
      display: none;
      position: absolute;
      left: 10%;
      transform: translateY(-50%);

      height: 25px;
      width: 150px;
      border-radius: 4px;

      font-size: 16px;
      padding-bottom: 2px;
      font-weight: bold;
      color: var(--bg-white);
      background-color: var(--primary);
      border: 1px solid var(--bg-white);

      align-items: center;
      justify-content: center;
    }

    &:hover {
      z-index: 999;

      height: 3px;
      background-color: var(--primary) !important;

      &::after {
        display: flex;
      }
    }
  }

  .step-adder.parent {
    &::after {
      content: '+ add parent-step';
      left: 0;
    }
  }

  .step-adder.child {
    &::after {
      content: '+ add child-step';
      left: 15%;
    }
  }

  .actions-footer {
    display: flex;
    justify-content: space-between;

    .our-btn.quaternary.back-btn {
      margin: auto 0 auto 32px !important;
    }

    .reset-changes-btn {
      color: var(--warning-error);
      border-color: var(--warning-error);

      margin: auto 48px auto 0 !important;
    }
  }
}
