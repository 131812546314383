.col.product-details {
  flex: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: initial;
  width: 250px;
  box-shadow: 0px 2px 4px 0px #b3b3b3;
  padding: 8px;

  &.raw-recommendation {
    opacity: 0.5;
  }
}

.col.product-details .container {
  padding: 0;
}

.col.product-details .row.model-name {
  margin-bottom: 5px;
}

.col.product-details .row.model-name,
.col.product-details .row.name {
  text-transform: capitalize;
}

.col.product-details .row.display {
  margin-bottom: 22px;
}

.col.product-details .row.model-name .col,
.col.product-details .row.display .col {
  padding-left: 0;
  padding-right: 0;
}

.col.product-details .row.display .col > img {
  max-height: 150px;
}

.col.product-details .model-name.row {
  margin: 0;
}

.col.product-details .row.display .col.slot {
  display: flex;
  justify-content: flex-end;
  margin: 0 -13px 0 13px;
  z-index: 1;
}

.col.product-details .row.display .col.slot .badge {
  align-self: flex-start;
}

.col.product-details .row.display .col.image {
  display: flex;
  justify-content: center;
  min-width: 150px;
  height: 150px;
}

.col.product-details .row.display .col.external-link {
  margin: 0 9px 0 -9px;
  z-index: 1;
}

.col.product-details .row.display .col.external-link > a {
  cursor: pointer;
}

.col.product-details .row.model-name,
.col.product-details .row.name,
.col.product-details .row.sku {
  font-weight: 700;
}

.col.product-details .row.name,
.col.product-details .row.sku {
  text-align: center;
  margin-bottom: 0;
}

.col.product-details .row.name {
  font-size: 16px;
  color: #aaaaaa;
}

.col.product-details .row.model-name,
.col.product-details .row.sku {
  font-size: 12px;
  color: #715fcc;
}

.col.product-details .row.brand-category {
  display: flex;
  justify-content: center;
  margin-bottom: 4px !important;
}

.col.product-details .row.brand-category .badge {
  width: fit-content;
  color: #424242 !important;
  font-weight: 400;
}

.col.product-details .row.brand-category .badge:not(:last-child) {
  margin-right: 4px;
}

.col.product-details .row.brand-category .badge.brand {
  background-color: #cdfcbd !important;
}

.col.product-details .row.brand-category .badge.category {
  background-color: #fff3d6 !important;
}
