.scenario-history-drawer {
  height: 100%;

  display: flex;
  flex-direction: column;

  > header {
    height: 100px;
  }

  > section {
    height: calc(100% - 100px);

    overflow-y: scroll;

    .scenarios-versions-accordion {
      .version-item {
        position: relative;
        box-shadow: none !important;
        border: none;
        border-left: 1px solid var(--detail-gray) !important;
        border-radius: 0 !important;
        margin: 0 8px;

        th:not(:last-child), td {
          padding: 8px;
        }

        th:last-child {
          padding: 0 0 0 10px;
        }

        td > span {
          white-space: break-spaces;
        }

        .parent-step-name::after {
          visibility: hidden;
        }

        &:hover {
          background: var(--bg-gray);
          .accordion-button::after {
            visibility: visible;
          }
          .history, .history thead > tr > th {
            background: var(--bg-gray);
          }
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 16px;
          transform: translateX(-50%);

          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: var(--detail-gray);
        }

        &.LIVE {
          &::before {
            top: 0;
            background: var(--text-black);
          }
        }

        .scenario-version-header {
          display: flex;
          flex-direction: column;
          gap: 6px;
        }

        .accordion-button:focus {
          box-shadow: unset !important;
        }

        .accordion-button:after {
          visibility: hidden;
        }
      }
    }
  }
}
