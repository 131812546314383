.qa-navbar-container {
  position: absolute;
  top: -8px;
  left: -82px;
  transform: rotate(-45deg);
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .qa-settings-container-slot {
    height: 25px;
    width: 100%;
  }

  .qa-settings-button {
    height: 100%;
    width: 100%;
    background: var(--bg-white);
    padding: 0;
    border: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .env-band {
    background: var(--bg-white);
    display: inline;
    width: 100%;
    border-top: 1px solid var(--primary);

    color: var(--primary);
    font-weight: 800;
    font-size: 16px;
    padding: 2px;
    margin: 0;
    text-align: center;
  }
}
