.edition-confirmation-container {
  display: flex;
  gap: 4px;

  .edition-button {
    height: 32px;
    width: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--tertiary);
    border: 1px solid var(--secondary);
    border-radius: 4px;
  }
}
