.authenticated-aside-main {
  display: flex;
}

.authenticated-aside-main aside {
  position: sticky;
  top: 56px;
  height: calc(100vh - 56px);
}

.authenticated-aside-main main {
  width: 100%;

  .container {
    max-width: unset;
  }
}
