.container.model-tester {
  padding: 0;
}

.container.model-tester .card {
  margin: 32px 24px;
}

.container.model-tester .card-body {
  margin-top: 28px;
  margin-left: 33px;
  margin-right: 27px;
  padding: 0 !important;
}

.container.model-tester .card-title {
  margin-top: 20px;
  font-size: 32px;
  font-weight: 700;
}

.container.model-tester .card-title > h5,
.container.model-tester .card-title > h6 {
  text-align: end;
  color: #424242;
}

.container.model-tester .card-title .second-line {
  font-size: 12px;
  font-weight: 400;
}

.container.model-tester .card-text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 700;
}

.container.model-tester .row.title ~ .card-text {
  margin-bottom: 24px;
}

.container.model-tester .row.title ~ .card-text.input-description {
  margin-bottom: 15px;
}

.container.model-tester .row {
  margin-bottom: 24px;
}

.container.model-tester .row.title {
  margin-bottom: 8px;
}

.container.model-tester .row.products {
  margin-bottom: 33px;
  justify-content: space-between;
  row-gap: 53px;
}

.container.model-tester .card:not(:first-child) .row.products {
  margin-bottom: 17px;
}

.container.model-tester .row.submit {
  margin-bottom: 61px;
}

.container.model-tester .row.submit > .col > .btn {
  font-size: 16px;
  font-weight: 400;
  width: 318px;
  height: 53px;
}

.recommendation-results-container {
  position: relative;

  .execution-metrics {
    position: absolute;
    top: 32px;
    right: 32px;

    display: flex;
    flex-direction: column;
  }

  .products-container {
    margin: 24px 0;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;

    .back-btn {
      margin: auto 0 auto 32px !important;
      color: var(--primary);
      text-decoration: underline;
    }

    .confirm {
      padding: 12px 48px !important;
    }

    button[variant='quaternary'] {
      margin: auto 0;
    }
  }
}