.row.user-signed-out {
  margin-bottom: 0;
  margin-right: 0;
}

.row.user-signed-out .col {
  padding-left: 10px;
  padding-right: 0;
}

.row.user-signed-out .col.sign-in {
  display: flex;
  align-items: center;
  min-width: fit-content;
}

.row.user-signed-out .col .sign-in {
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-align: right;
  text-decoration: none;
  margin-bottom: unset;
}

.row.user-signed-out .col.picture-container {
  display: flex;
  align-items: center;
}

.row.user-signed-out .picture {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  background-color: #dddddd;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

.row.user-signed-out .picture > img {
  opacity: 0.24;
}
