.container.metrics-tile {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container.metrics-tile.clickable > .row > .col,
.container.metrics-tile.clickable > .row.title > .col-sm-1.external-link {
  cursor: pointer;
}

.container.metrics-tile.clickable > .row.title > .col-sm-1.external-link {
  margin: 0 16px 0 -16px;
  z-index: 1;
}

.container.metrics-tile.clickable > .row.title > .col-sm-1.external-link > img {
  vertical-align: baseline;
}

.container.metrics-tile.clickable:hover > .row > .col {
  color: #715fcc;
}

.container.metrics-tile > .row.title > .col {
  text-transform: capitalize;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: black;
}

.container.metrics-tile > .row.formatted-data > .col {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: black;
}
