.parent-step-viewer-container {
  border: 1px solid var(--detail-gray);

  h3 {
    font-weight: bold;
    padding: 8px 8px 0 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .steps-list {
    padding: 8px;
    margin-top: 16px;
    margin-left: 16px;

    max-height: 160px;
    overflow: auto;
  }
}

.step-item {
  margin-bottom: 16px;

  header {
    display: flex;
    align-items: center;

    h6 {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }

  section {
    margin-left: 16px;
  }

  .step-figure {
    display: block;
    width: 8px;
    height: 8px;

    &.step-rect {
      background: var(--primary);
    }

    &.step-circle {
      border-radius: 50%;
      background: var(--detail-border);
    }
  }
}
