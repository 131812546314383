.navbar {
  padding-top: 5.5px;
  padding-bottom: 5px;
  overflow: hidden;
  border-bottom: 1px solid var(--primary);
}

.navbar > .container-fluid {
  padding-left: 64px;
  padding-right: 30px;
}

.navbar > .container-fluid > .navbar-brand {
  font-weight: 700;
  font-size: 12px;
  color: var(--bg-white);
}

.navbar > .container-fluid > .navbar-brand > img {
  margin-right: 16px;
}

.navbar > .container-fluid .user-container {
  display: flex;
  align-items: center;
}
