.dialog-container {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  overflow: hidden;
  height: 100%;

  h2 {
    font-weight: 700;
    font-size: 32px;
  }

  h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .dialog-header {
    display: flex;
    justify-content: space-between;
    height: 56px;

    &:has(.subtitle) {
      height: 92px;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      width: 100%;
    }

    svg {
      cursor: pointer;
      path {
        fill: var(--detail-border);
      }
    }
  }

  .dialog-content-container {
    height: 100%;
    overflow: auto;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    height: 50px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100% !important;

      button {
        width: 100% !important;
      }
    }

    &.secondary {
      span:first-child button {
        width: auto !important;
      }
    }
  }

  .new-model-dialog {
    max-width: 666px;
    width: 666px;
    overflow-x: hidden;
  }
}