.configuration-card-container {
  width: 100%;
  min-height: 126px;
  border: 1px solid var(--detail-border);
  border-radius: 10px;
  box-shadow: 0px 2px 4px 0px #b3b3b3;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.card {
    margin: 0 !important;
  }

  &:hover {
    background-color: var(--tertiary);
  }

  .configuration-card-body {
    display: flex;
    gap: 8px;

    &.card-body--with-margin {
      margin: 16px;
      flex-direction: column;
    }

    &.card-body--aligned {
      align-items: center;
    }
  }
}

.title-test-test {
  margin: 0;
}
