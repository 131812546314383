.save-confirmation-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: all 200ms ease-in-out;
  height: 61px;
  overflow: hidden;

  background: var(--bg-yellow);
  padding: 12px;

  &.hidden {
    height: 0;
    padding: 0 12px;
  }

  .save-confirmation-content {
    display: flex;
    flex-direction: column;

    h4 {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: var(--text-black);
      margin: 0;
    }

    p {
      margin: 0 !important;
      font-size: 16px;
      color: var(--text-gray);
    }
  }
}
