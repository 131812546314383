.separator-input .form-input-container.form-control {
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  padding: 0;
  /* width: 100%; */
}

.separator-input .form-input-container.form-control > .badge {
  display: inline;
  margin: auto 0.375rem;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.separator-input .form-input-container.form-control > .badge:not(:last-of-type) {
  margin-right: 0;
}

.separator-input .form-input-container > .form-control {
  display: inline-block;
  flex: 1;
  border: unset;
  width: auto;
  min-width: 8rem;
}

.separator-input .form-input-container > .form-control:focus {
  border-color: unset !important;
  box-shadow: unset !important;
}
