.icon-btn {
  height: 24px;
  padding-left: 0;
  border: none;
  display: flex;
  align-items: center;
  color: black !important;
  font-weight: 700;
  font-size: 20px;
}

button.icon-btn,
button.icon-btn:focus,
button.icon-btn:active,
a.icon-btn,
a.icon-btn:focus,
a.icon-btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.icon-btn.default.has-children svg {
  min-width: fit-content;
  margin-right: 16px;
}

.icon-btn.link.has-children svg {
  min-width: fit-content;
  margin-left: 16px;
}

.icon-btn.link {
  height: 24px;
  border: none;
  display: flex;
  align-items: center;
  color: var(--primary) !important;
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  flex-direction: row-reverse;
}

.icon-btn.link,
.icon-btn.link:focus,
.icon-btn.link:active {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.icon-btn.spinning > svg > g {
  animation-duration: 1s !important;
  animation-direction: reverse !important;
}
