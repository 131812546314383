.scenario-version-tester-container {
  .card {
    margin: 32px 24px;

    .dropdown-test-scenario-market {
      width: 400px;
      margin-top: 32px;
    }

    .params-actions-container {
      display: flex;
      justify-content: center;
    }

    .recommendation-results-container {
      position: relative;

      .execution-metrics {
        position: absolute;
        top: 32px;
        right: 32px;

        display: flex;
        flex-direction: column;
      }

      .products-container {
        margin: 24px 0;
      }

      .actions-container {
        display: flex;
        justify-content: space-between;

        button[variant='quaternary'] {
          margin: auto 0;
        }
      }
    }
  }
}
