.closable-badge.badge {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  color: #424242;

  &.verifier {
    &.pending {
      background-color: var(--bg-yellow) !important;

      .spinner {
        border-color: var(--warning-orange);
        border-right-color: transparent;
      }
    }

    &.verified {
      background-color: var(--bg-green) !important;
    }

    &.not-verified {
      background-color: var(--bg-red) !important;
    }
  }

  padding-right: 24px;
}

.closable-badge.badge.bg-primary.gray {
  background-color: #f0f1ff !important;
}

.closable-badge.badge.bg-primary.red {
  background-color: #fff0f0 !important;
}

.closable-badge.badge.bg-primary.orange {
  background-color: #fff3d6 !important;
}

.closable-badge.badge.bg-primary.green {
  background-color: #cdfcbd !important;
}

.closable-badge.badge .btn-close {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-20%, -50%);

  width: 8px;
  height: 8px;
  padding: 0 0 0 1em;
}
