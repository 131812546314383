.scenario-tester-form .row {
  margin-bottom: 24px;
}

.scenario-tester-form .row ~ .row.params-description {
  margin-top: 46px;
  margin-bottom: 12px;
}

.scenario-tester-form .row:last-child {
  margin-bottom: 48px;
}
