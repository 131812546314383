.paginated-table {
  position: relative;
}

.paginated-table tfoot > tr > td > div > p {
  margin: 0;
}

.paginated-table thead > tr > th {
  color: white;
  background-color: #715fcc;
}

.paginated-table p {
  margin-bottom: 0;
  font-family: 'Lato', sans-serif !important;
}

.paginated-table select,
.paginated-table option {
  font-family: 'Lato', sans-serif !important;
}

.paginated-table option {
  text-align: center;
}

.paginated-table.history thead > tr > th {
  color: #715fcc;
  background: white;
  border-color: #715fcc;
}

.read-only {
  .MuiTableCell-root {
    background-color: white;
    color: var(--primary);
  }

  .MuiTableCell-body {
    color: var(--text-black);
    min-width: 75px;
  }
}

.pencil-icon-edit-row {
  cursor: pointer;
  position: relative;

  &.disabled {
    cursor: not-allowed !important;
  }

  .has-changes {
    &::after {
      content: '!';
      position: absolute;
      top: -5px;
      right: 15px;
      transform: translate(25%, -25%);

      height: 16px;
      width: 16px;
      font-size: 12px;
      line-height: 12px;
      background: var(--warning-orange);
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
