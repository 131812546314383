.toast-container {
  position: fixed !important;

  .toast {
    .toast-header {
      color: #424242;
      position: relative;

      .toast-date {
        position: absolute;
        right: 24px;
        font-size: 10px;
        color: #858e93;
      }

      .btn-close {
        height: 3px;
        width: 3px;
      }

      .toast-body {
        color: #424242;
      }
    }

    &.success {
      border: 1px solid #4aa04a;
      background: #dbecdb;
      .toast-header {
        background: #dbecdb;
        border-bottom: 1px solid #4aa04a;
      }
    }

    &.error {
      border: 1px solid #ff4949;
      background: #fff0f0;
      .toast-header {
        background: #fff0f0;
        border-bottom: 1px solid #ff4949;
      }
    }

    &.warning {
      border: 1px solid #fa9f47;
      background: #fff3f6;
      .toast-header {
        background: #fff3f6;
        border-bottom: 1px solid #fa9f47;
      }
    }
  }
}
