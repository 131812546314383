.dashboard-form .row {
  margin-bottom: 24px;
}

.dashboard-form .row:last-child {
  margin-bottom: 0 !important;
}

.dashboard-form .row:last-child > .col-md-3.submit {
  display: flex;
}

.dashboard-form .row:last-child > .col-md-3.submit > .btn {
  align-self: flex-end;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 38px;
}
