.parent-step-name {
  position: relative;
  display: block;
  width: 100%;
  max-width: 500px;

  &::after {
    content: attr(data-label);
    position: absolute;
    bottom: -100%;
    left: 0;

    width: 100%;
    font-size: 12px;
    color: var(--text-gray);

    overflow: hidden;
    text-overflow: ellipsis;
  }

  &[data-label-hidden='true']::after {
    display: none;
  }
}
