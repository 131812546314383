.search-input {
  position: relative;

  .form-label {
    font-size: 12px;
    margin-left: 12px;
    margin-bottom: 0 !important;
  }

  .form-control {
    background-color: var(--bg-white) !important;
    border: none !important;
    border-bottom: 1px solid #c2cace !important;

    &:focus {
      box-shadow: none !important;
    }
  }

  .helper-icon {
    position: absolute;
    bottom: 0;
    right: 12px;

    transform: translate(0, -50%);

    &.actionable {
      cursor: pointer;
    }
  }
}
