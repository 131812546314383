.row.polling-info .col {
  color: #715fcc;
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
}

.row.polling-info .col p {
  margin-bottom: 0;
}

.row.polling-info .col .real-time {
  color: #88db88;
}
