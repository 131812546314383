.dropdown-market-merch-rules {
  div {
    &.dropdown {
      margin-top: 9px;
    }
  }
}

.single-select-dropdown.primary .dropdown > .dropdown-toggle.btn:disabled {
  background-color: var(--warning-disable) !important;
  border: 1px solid var(--detail-border);
}
