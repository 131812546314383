.cache-reloader-form .row.submit > .col > .btn {
  font-size: 16px;
  font-weight: 400;
  width: 200px;
  height: 53px;
}

.cache-reloader-form .form-label.required::after {
  content: '*';
  color: red;
}
