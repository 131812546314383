.scenario-list-form .row:last-child {
  margin-bottom: 0 !important;
}

.scenario-list-form .row.submit > .col > .btn {
  font-size: 16px;
  font-weight: 400;
  width: 220px;
  height: 48px;
}

.scenario-list-form .dropdown {
  width: 0px;
  min-width: 100%;
}
