.child-steps-container {
  border: 1px solid var(--detail-border);
  padding: 0 8px;

  height: 100%;
  width: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;

  .child-step-item-container {
    display: grid;
    grid-template-columns: 1fr 19fr;
    padding: 24px 4px;
    cursor: default;

    &:not(:first-child) {
      border-top: 1px solid var(--detail-border);
    }

    .child-step-item-content {
      display: flex;
      flex-direction: column;
    }
  }
}
