.accordionv2 {
  max-height: calc(100% - 56px);
  height: max-content;
  border-bottom: 1px solid var(--detail-gray);

  .accordion-body {
    padding: 1rem 0;
  }
}

.accordionv2-item {
  box-shadow: none !important;
  border: 0 !important;
  margin-bottom: 0 !important;

  > .accordion-header {
    top: 0;
    position: sticky;
    background: var(--bg-white);
    z-index: 99;
    margin: 0;

    > .accordion-button {
      border: none !important;
      outline: 0 !important;
      box-shadow: none !important;

      &.collapsed .header-chevron {
        transform: rotate(180deg);
      }

      &::after {
        display: none;
      }
    }
  }

  > .accordion-collapse {
    max-height: calc(100% - 56px);
  }

  .accordionv2-item-header {
    display: flex;
    flex-direction: row;
  }
}
