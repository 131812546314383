.search-text {
  position: relative;

  .search-icon {
    position: absolute;
    right: 0;
    margin: 10px;
  }

  .search-text-ctrl {
    width: 357px;
    height: 45px;
  }
}

.pending {
  background: var(--bg-blue, #e4effd);
  padding: 12px;
  margin-bottom: 32px;

  .cancel {
    color: var(--warning-error);
    cursor: pointer;
    text-decoration: underline;
  }
}

.action-button:disabled {
  background: var(--bs-gray) !important;
}

.container.scenario-tester {
  padding: 0;
}

.container.scenario-tester .card {
  margin: 32px 24px;
}

.container.scenario-tester .card-body {
  margin-top: 28px;
  margin-left: 33px;
  margin-right: 27px;
  padding: 0 !important;
}

.container.scenario-tester .card-title {
  font-size: 32px;
  font-weight: 700;
}

.container.scenario-tester .card-title > h5,
.container.scenario-tester .card-title > h6 {
  text-align: end;
  color: #424242;
}

.container.scenario-tester .card-title .second-line {
  font-size: 12px;
  font-weight: 400;
}

.container.scenario-tester .card-text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 700;
}

.container.scenario-tester .row.title ~ .card-text {
  margin-bottom: 24px;
}

.container.scenario-tester .row.title ~ .card-text.input-description {
  margin-bottom: 15px;
}

.container.scenario-tester .row {
  margin-bottom: 24px;
}

.container.scenario-tester .row.title {
  margin-bottom: 8px;
}

.container.scenario-tester .row.products {
  margin-bottom: 33px;
  justify-content: space-between;
  row-gap: 53px;
}

.container.scenario-tester .card:not(:first-child) .row.products {
  margin-bottom: 17px;
}

.container.scenario-tester .row.submit {
  margin-bottom: 61px;
}

.container.scenario-tester .row.submit > .col > .btn {
  font-size: 16px;
  font-weight: 400;
  width: 318px;
  height: 53px;
}
