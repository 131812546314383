.container.scenario-list {
  padding: 0;
}

.container.scenario-list .card {
  margin: 32px 24px;
}

.container.scenario-list .card-body {
  margin-top: 28px;
  margin-left: 32px;
  margin-right: 38px;
  margin-bottom: 45px;
  padding: 0 !important;
}

.container.scenario-list .card-title {
  font-size: 32px;
  font-weight: 700;
}

.container.scenario-list .card-title .second-line {
  font-size: 12px;
  font-weight: 400;
}

.container.scenario-list .card-text {
  color: #aaaaaa;
  font-size: 16px;
  font-weight: 700;
}

.container.scenario-list .row.title ~ .card-text {
  margin-bottom: 24px;
}

.container.scenario-list .row {
  margin-bottom: 24px;
}

.container.scenario-list .row.title {
  margin-bottom: 8px;
}
