.products-container {
  margin: 24px 0;

  display: grid;
  gap: 32px;
  grid-template-columns: repeat(4, minmax(250px, 1fr));
}

.new-model-products {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  grid-template-columns: repeat(2, minmax(250px, 1fr)) !important;
  border: 1px solid #ced4da;
  min-width: 660px;
  margin-left: 14px !important;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 55px;
}