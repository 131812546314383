.actions-container {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-bottom: 16px;

  .action-button {
    display: block;
    position: relative;

    height: 45px;
    width: 45px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--bg-white);
    background: var(--primary);
    border-radius: 10px;
    border: 0;

    &.has-changes {
      &::after {
        content: '!';
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(25%, -25%);

        height: 16px;
        width: 16px;
        font-size: 12px;
        line-height: 12px;
        background: var(--warning-orange);
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
