.permission-features-container {
  display: grid;
  grid-template-columns: 1fr 3fr 4fr 3fr;
  justify-content: flex-start;

  padding: 8px;

  &[data-disabled='true'] {
    opacity: 0.5;
  }

  .multi-select-dropdown {
    width: 265px;
  }

  &:not(:first-child) {
    border-top: 1px solid var(--detail-border);
  }
}
