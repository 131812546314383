.search-text {
  position: relative;

  .search-icon {
    position: absolute;
    right: 0;
    margin: 10px;
  }

  .search-text-ctrl {
    width: 357px;
    height: 45px;
  }
}

.pending {
  background: var(--bg-blue, #e4effd);
  padding: 12px;
  margin-bottom: 32px;

  .cancel {
    color: var(--warning-error);
    cursor: pointer;
    text-decoration: underline;
  }
}

.action-button:disabled {
  background: var(--bs-gray) !important;
}
