.MuiSwitch-root {
  .MuiSwitch-switchBase .MuiSwitch-thumb {
    background: var(--detail-gray);
  }

  .MuiSwitch-switchBase + .MuiSwitch-track {
    background: var(--detail-gray);
    opacity: 0.5;
  }

  .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
    background: var(--primary);
  }

  .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background: var(--primary) !important;
    opacity: 0.5;
  }
}
