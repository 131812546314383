.add-child-step-modal-container {
  width: 640px;
  height: 360px;

  #step-filter {
    height: 37px;
    margin-bottom: 16px;
  }

  .add-child-step-modal-content {
    height: calc(360px - (37px + 16px));
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
