aside {
  position: sticky;
  top: 56px;
  height: calc(100vh - 56px);
}

main {
  width: 100%;
}

.aside-main {
  display: flex;
}
